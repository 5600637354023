<template>
    <div>
        <div class="border-t">
            <Breadcrumb />
        </div>
        <div class="mt-5 mx-5">
            <router-view class="mt-2 mb-2"></router-view>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import Breadcrumb from "../../../components/Breadcrumb.vue";
export default {
    components: {
        Breadcrumb,
    },
    computed: {
        ...mapState("i18nStore", ["langDatas"]),
    },
    beforeRouteLeave(to, from, next) {
        // location.reload();
        return next();
    },
};
</script>
